import { amgApi } from "@/service/axios";

class TimeService {
  async updateTime(params) {
    try {
      const data = await amgApi.post(
        "management/charge-back/update-time",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }


  async getTime() {
    try {
      const data = await amgApi.post(
        "management/charge-back/get-limit-time"
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async getTimeTracking(params){
    try {
      const data = await amgApi.post(
        "management/charge-back/get-time-tracking",
        params
      );
      return data;
    } catch (error) {
      
    }
  }
}

export default new TimeService();
