<template>
  <b-card>
    <b-card-title class="mr-2 d-flex align-items-center">
      <tabler-icon icon="CoinIcon" size="18" class="mr-50 text-danger" />
      PENALTIES
    </b-card-title>
    <b-row>
      <b-col
        lg="4"
        md="4"
        class="my-2"
        v-for="(pen, index) in penalties"
        :key="index"
      >
        <feather-icon
          icon="SquareIcon"
          style="fill: #0090e7"
          class="text-primary mr-1"
        />
        <span style="font-weight: bold">
          {{ pen.description }}
        </span>
        <b-input-group class="mt-2">
          <b-input-group-prepend>
            <b-button variant="outline-secondary" class="btn-icon">
              <tabler-icon icon="AlarmIcon" class="text-primary" />
            </b-button>
          </b-input-group-prepend>

          <money
            v-model="pen.penalty"
            v-bind="vMoney"
            class="form-control input-form"
            :disabled="!pen.editing"
          />
          <b-input-group-append>
            <b-button
              v-if="!pen.editing"
              variant="outline-warning"
              class="btn-icon"
              @click="pen.editing = true"
              v-b-tooltip.hover.top="'Edit'"
            >
              <feather-icon icon="Edit2Icon" class="cursor-pointer" />
            </b-button>
            <b-button
              v-if="pen.editing"
              variant="outline-primary"
              class="btn-icon"
              v-b-tooltip.hover.top="'Save'"
              @click="savePenalty(pen)"
            >
              <feather-icon icon="SaveIcon" class="cursor-pointer" />
            </b-button>
            <b-button
              v-if="pen.editing"
              variant="outline-danger"
              class="btn-icon"
              @click="cancelEdit(pen)"
              v-b-tooltip.hover.top="'Cancel'"
            >
              <feather-icon icon="XIcon" class="cursor-pointer" />
            </b-button>
          </b-input-group-append>
          <b-input-group-append
            class="cursor-pointer"
            v-b-tooltip.hover.top="'Tracking'"
          >
            <b-input-group-text @click="showTracking(pen)">
              <feather-icon icon="ListIcon" class="text-primary" />
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <tracking-penalty
      v-if="trackingShow"
      :id-merchant="idMerchant"
      @close="trackingShow = false"
    />
  </b-card>
</template>

<script>
import TrackingPenalty from "@/views/management/views/settings/views/other/modal/TrackingPenalty.vue"
import OtherSettingServices from "@/views/management/views/settings/views/other/service/index.js"
import { mapGetters } from "vuex"
export default {
  components: {
    TrackingPenalty,
  },
  data() {
    return {
      penalties: [],
      vMoney: {
        decimal: ".",
        thousand: ",",
        precision: 2,
        prefix: "$ ",
        min: 0.0,
        maxlength: 10,
      },
      trackingShow: false,
      idMerchant: null,
    }
  },
  methods: {
    async getPenalties() {
      const { data } = await OtherSettingServices.getPenalties()
      this.penalties = data.map((pen) => {
        return {
          ...pen,
          penaltyOld: pen.penalty,
          editing: false,
        }
      })
    },
    showTracking(pen) {
      this.idMerchant = pen.id
      this.trackingShow = true
    },
    cancelEdit(pen) {
      pen.editing = false
      pen.penalty = pen.penaltyOld
    },
    async savePenalty(pen) {
      const response = await this.showConfirmSwal(
        "Are you sure you want to update this penalty?"
      )
      if (!response.value) return
      let params = {
        ...pen,
        user_id: this.currentUser.user_id,
      }
      await OtherSettingServices.updatePenalty(params)
      pen.editing = false
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.getPenalties()
  },
}
</script>
