<template>
  <b-modal
    v-model="onControl"
    header-bg-variant="primary"
    title-class="h3 text-white font-weight-bolder"
    size="xmd"
    title="Tracking"
    modal-class="modal-primary"
    hide-footer
    scrollable
    @hidden="close"
  >
    <filter-slot
      :no-visible-principal-filter="true"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :filter="[]"
      :filter-principal="{}"
      @reload="$refs['refTrackingTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="refTrackingTable"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="myProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(old_value)="data">
            <span>{{ data.item.old_value }} Hrs</span>
          </template>
          <template #cell(new_value)="data">
            <span>{{ data.item.new_value }} Hrs</span>
          </template>
          <template #cell(created_at)="data">
            <span>{{ data.item.created_at | myGlobalWithHour }}</span>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>
<script>
import TimeService from "@/views/management/views/settings/views/time/service/index"
export default {
  props: {
    idTime: {
      type: Number,
    },
  },
  data() {
    return {
      trackingItems: [],
      onControl: false,
      fields: [
        {
          key: "old_value",
          label: "Old Value",
        },
        {
          key: "new_value",
          sortable: false,
          label: "New Value",
        },
        {
          key: "created_by",
          sortable: true,
          label: "Requested by",
        },
        {
          key: "created_at",
          sortable: true,
          label: "Update Date",
        },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "name",
      sortDesc: true,
      searchInput: "",
    }
  },
  async created() {
    this.onControl = true
    this.addPreloader()
    this.removePreloader()
  },
  methods: {
    close() {
      this.$emit("close")
    },
    async myProvider(ctx) {
      const response = await TimeService.getTimeTracking({
        page: ctx.currentPage,
        perPage: ctx.perPage,
        limit_time_id: this.idTime,
      })
      this.trackingItems = response.data.data
      this.startPage = response.data.from
      this.paginate.currentPage = response.data.current_page
      this.paginate.perPage = response.data.per_page
      this.totalRows = response.data.total
      this.toPage = response.data.to
      return this.trackingItems || []
    },
  },
}
</script>
