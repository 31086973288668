<template>
  <b-container class="m-0 w-100 pt-2" fluid>
    <b-card>
      <b-row>
        <b-card-title class="mr-2"> CATEGORIES: </b-card-title>
        <div class="mr-3">
          <feather-icon
            icon="SquareIcon"
            style="margin-right: 0.5rem; fill: #0090e7"
            class="text-primary"
          />
          HOURS
        </div>
        <div v-if="$route.meta.moduleIdNum != 20">
          <feather-icon
            icon="SquareIcon"
            style="margin-right: 0.5rem; fill: #ff9f43"
            class="text-warning"
          />
          DAYS
        </div>
        <div class="ml-2">
          <feather-icon
            class="cursor-pointer"
            :class="isBusy ? 'lds-ring' : ''"
            icon="RefreshCcwIcon"
            size="18"
            @click="getTime"
          />
        </div>
      </b-row>
      <b-row>
        <b-col lg="4" md="4" class="my-2">
          <feather-icon
            icon="SquareIcon"
            style="fill: #0090e7"
            class="text-primary mr-1"
          />
          <span style="font-weight: bold">
            TIME LIMIT FOR FILE UPLOAD IN DISPUTES
          </span>
          <b-input-group class="mt-2">
            <b-input-group-prepend>
              <b-button variant="outline-secondary" class="btn-icon">
                <tabler-icon icon="AlarmIcon" class="text-primary" />
              </b-button>
            </b-input-group-prepend>

            <b-form-spinbutton
              v-model="hourFile"
              class="square"
              min="1"
              max="1000"
              :disabled="disableEditFile"
            />
            <b-input-group-append>
              <b-button
                v-if="disableEditFile"
                variant="outline-warning"
                class="btn-icon"
                @click="editActivate(1)"
                v-b-tooltip.hover.top="'Edit'"
              >
                <feather-icon icon="Edit2Icon" class="cursor-pointer" />
              </b-button>
              <b-button
                v-if="!disableEditFile"
                variant="outline-primary"
                class="btn-icon"
                @click="saveHour(1)"
                v-b-tooltip.hover.top="'Save'"
              >
                <feather-icon icon="SaveIcon" class="cursor-pointer" />
              </b-button>
              <b-button
                v-if="!disableEditFile"
                variant="outline-danger"
                class="btn-icon"
                @click="cancelEditTime(1)"
                v-b-tooltip.hover.top="'Cancel'"
              >
                <feather-icon icon="XIcon" class="cursor-pointer" />
              </b-button>
            </b-input-group-append>
            <b-input-group-append
              class="cursor-pointer"
              v-b-tooltip.hover.top="'Tracking'"
            >
              <b-input-group-text @click="openModalTrackingTime(1)">
                <feather-icon icon="ListIcon" class="text-primary" />
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col lg="4" md="4" class="my-2">
          <feather-icon
            icon="SquareIcon"
            style="fill: #0090e7"
            class="text-primary mr-1"
          />
          <span style="font-weight: bold">TIME LIMIT FOR CLIENT PAYMENT</span>
          <b-input-group class="mt-2">
            <b-input-group-prepend>
              <b-button variant="outline-secondary" class="btn-icon">
                <tabler-icon icon="AlarmIcon" class="text-primary" />
              </b-button>
            </b-input-group-prepend>

            <b-form-spinbutton
              v-model="hourPayment"
              class="square"
              min="1"
              max="1000"
              :disabled="disableEditPayment"
              @input="updateValue"
            />
            <b-input-group-append>
              <b-button
                v-if="disableEditPayment"
                variant="outline-warning"
                class="btn-icon"
                @click="editActivate(2)"
                v-b-tooltip.hover.top="'Edit'"
              >
                <feather-icon icon="Edit2Icon" class="cursor-pointer" />
              </b-button>
              <b-button
                v-if="!disableEditPayment"
                variant="outline-primary"
                class="btn-icon"
                @click="saveHour(2)"
                v-b-tooltip.hover.top="'Save'"
              >
                <feather-icon icon="SaveIcon" class="cursor-pointer" />
              </b-button>
              <b-button
                v-if="!disableEditPayment"
                variant="outline-danger"
                class="btn-icon"
                @click="cancelEditTime(2)"
                v-b-tooltip.hover.top="'Cancel'"
              >
                <feather-icon icon="XIcon" class="cursor-pointer" />
              </b-button>
            </b-input-group-append>
            <b-input-group-append
              class="cursor-pointer"
              v-b-tooltip.hover.top="'Tracking'"
            >
              <b-input-group-text @click="openModalTrackingTime(2)">
                <feather-icon icon="ListIcon" class="text-primary" />
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col lg="4" md="4" class="my-2">
          <feather-icon
            icon="SquareIcon"
            style="fill: #0090e7"
            class="text-primary mr-1"
          />
          <span style="font-weight: bold">
            TIME LIMIT TO ASSIGN THE CHARGEBACK SOLUTION
          </span>
          <b-input-group class="mt-2">
            <b-input-group-prepend>
              <b-button variant="outline-secondary" class="btn-icon">
                <tabler-icon icon="AlarmIcon" class="text-primary" />
              </b-button>
            </b-input-group-prepend>

            <b-form-spinbutton
              v-model="hourAssign"
              class="square"
              min="1"
              max="1000"
              :disabled="disableEditAssign"
              @input="updateValue"
            />
            <b-input-group-append>
              <b-button
                v-if="disableEditAssign"
                variant="outline-warning"
                class="btn-icon"
                @click="editActivate(3)"
                v-b-tooltip.hover.top="'Edit'"
              >
                <feather-icon icon="Edit2Icon" class="cursor-pointer" />
              </b-button>
              <b-button
                v-if="!disableEditAssign"
                variant="outline-primary"
                class="btn-icon"
                @click="saveHour(3)"
                v-b-tooltip.hover.top="'Save'"
              >
                <feather-icon icon="SaveIcon" class="cursor-pointer" />
              </b-button>
              <b-button
                v-if="!disableEditAssign"
                variant="outline-danger"
                class="btn-icon"
                @click="cancelEditTime(3)"
                v-b-tooltip.hover.top="'Cancel'"
              >
                <feather-icon icon="XIcon" class="cursor-pointer" />
              </b-button>
            </b-input-group-append>
            <b-input-group-append
              class="cursor-pointer"
              v-b-tooltip.hover.top="'Tracking'"
            >
              <b-input-group-text @click="openModalTrackingTime(3)">
                <feather-icon icon="ListIcon" class="text-primary" />
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col lg="4" md="4" class="my-2">
          <feather-icon
            icon="SquareIcon"
            style="fill: #ff9f43"
            class="text-warning mr-1"
          />
          <span class="font-weight-bold">
            TIME LIMIT TO VERIFY BANK REIMBURSEMENT
          </span>
          <b-input-group class="mt-2">
            <b-input-group-prepend>
              <b-button variant="outline-secondary" class="btn-icon">
                <tabler-icon icon="CalendarIcon" class="text-warning" />
              </b-button>
            </b-input-group-prepend>

            <b-form-spinbutton
              v-model="dayBank"
              class="square"
              min="1"
              max="1000"
              :disabled="disableEditAssign"
              @input="updateValue"
            />
            <b-input-group-append>
              <b-button
                v-if="disableEditAssign"
                variant="outline-warning"
                class="btn-icon"
                @click="editActivate(3)"
                v-b-tooltip.hover.top="'Edit'"
              >
                <feather-icon icon="Edit2Icon" class="cursor-pointer" />
              </b-button>
              <b-button
                v-if="!disableEditAssign"
                variant="outline-primary"
                class="btn-icon"
                @click="saveHour(3)"
                v-b-tooltip.hover.top="'Save'"
              >
                <feather-icon icon="SaveIcon" class="cursor-pointer" />
              </b-button>
              <b-button
                v-if="!disableEditAssign"
                variant="outline-danger"
                class="btn-icon"
                @click="cancelEditTime(3)"
                v-b-tooltip.hover.top="'Cancel'"
              >
                <feather-icon icon="XIcon" class="cursor-pointer" />
              </b-button>
            </b-input-group-append>
            <b-input-group-append
              class="cursor-pointer"
              v-b-tooltip.hover.top="'Tracking'"
            >
              <b-input-group-text @click="openModalTrackingTime(3)">
                <feather-icon icon="ListIcon" class="text-primary" />
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col lg="4" md="4" class="my-2">
          <feather-icon
            icon="SquareIcon"
            style="fill: #ff9f43"
            class="text-warning mr-1"
          />
          <span style="font-weight: bold">
            TIME LIMIT TO VERIFY DISPUTE REIMBURSEMENT
          </span>
          <b-input-group class="mt-2">
            <b-input-group-prepend>
              <b-button variant="outline-secondary" class="btn-icon">
                <tabler-icon icon="CalendarIcon" class="text-warning" />
              </b-button>
            </b-input-group-prepend>

            <b-form-spinbutton
              v-model="dayBank"
              class="square"
              min="1"
              max="1000"
              :disabled="disableEditAssign"
              @input="updateValue"
            />
            <b-input-group-append>
              <b-button
                v-if="disableEditAssign"
                variant="outline-warning"
                class="btn-icon"
                @click="editActivate(3)"
                v-b-tooltip.hover.top="'Edit'"
              >
                <feather-icon icon="Edit2Icon" class="cursor-pointer" />
              </b-button>
              <b-button
                v-if="!disableEditAssign"
                variant="outline-primary"
                class="btn-icon"
                @click="saveHour(3)"
                v-b-tooltip.hover.top="'Save'"
              >
                <feather-icon icon="SaveIcon" class="cursor-pointer" />
              </b-button>
              <b-button
                v-if="!disableEditAssign"
                variant="outline-danger"
                class="btn-icon"
                @click="cancelEditTime(3)"
                v-b-tooltip.hover.top="'Cancel'"
              >
                <feather-icon icon="XIcon" class="cursor-pointer" />
              </b-button>
            </b-input-group-append>
            <b-input-group-append
              class="cursor-pointer"
              v-b-tooltip.hover.top="'Tracking'"
            >
              <b-input-group-text @click="openModalTrackingTime(3)">
                <feather-icon icon="ListIcon" class="text-primary" />
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <tracking-time
      v-if="trackingShow"
      :id-time="idTime"
      @close="closeTrackingTime"
    >
    </tracking-time>
  </b-container>
</template>

<script>
import TimeService from "@/views/management/views/settings/views/time/service/index"
import TrackingTime from "@/views/management/views/settings/views/time/modal/TrackingTime"
import { mapGetters } from "vuex"
export default {
  components: {
    TrackingTime,
  },
  data() {
    return {
      hourFile: null,
      hourFileOrigin: null,
      hourPayment: null,
      hourPaymentOrigin: null,
      hourAssign: null,
      hourAssignOrigin: null,
      disableEditFile: true,
      disableEditPayment: true,
      disableEditAssign: true,
      trackingShow: false,
      idTime: null,
      dayBank: 20,
      isBusy: false,
      // hourPaymentOld: null,
      // hourPaymentNew: null,
    }
  },
  methods: {
    async getTime() {
      try {
        this.addPreloader()
        const data = await TimeService.getTime()
        this.hourFile = data[0].hours
        this.hourFileOrigin = data[0].hours
        this.hourPayment = data[1].hours
        this.hourPaymentOrigin = data[1].hours
        this.hourAssign = data[2].hours
        this.hourAssignOrigin = data[2].hours
        return data
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader()
      }
    },
    editActivate(type) {
      if (type == 1) this.disableEditFile = false
      else if (type == 2) this.disableEditPayment = false
      else this.disableEditAssign = false
    },
    async cancelEditTime(type) {
      await this.getTime()
      if (type == 1) this.disableEditFile = true
      else if (type == 2) this.disableEditPayment = true
      else this.disableEditAssign = true
    },
    async saveHour(type) {
      const response = await this.showConfirmSwal(
        "Are you sure you want to update this time?"
      )
      if (!response.value) return
      let oldValue = this.hourAssignOrigin
      let hourValue = this.hourAssign

      if (type == 1) oldValue = this.hourFileOrigin
      else if (type == 2) oldValue = this.hourPaymentOrigin

      if (type == 1) hourValue = this.hourFile
      else if (type == 2) hourValue = this.hourPayment

      try {
        this.addPreloader()
        const data = await TimeService.updateTime({
          id: type,
          old_value: oldValue,
          hour: hourValue,
          created_by: this.currentUser.user_id,
          limit_time_id: type,
        })
        if (data.status === 200) {
          await this.getTime()
          this.showGenericSuccessSwal({})
          this.cancelEditTime(type)
        }
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader()
      }
    },
    openModalTrackingTime(type) {
      this.trackingShow = true
      this.idTime = type
    },
    closeTrackingTime() {
      this.trackingShow = false
    },
    updateValue(newValue) {
      this.oldValue = this.value
      this.value = newValue
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.getTime()
  },
}
</script>
