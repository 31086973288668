import { amgApi } from "@/service/axios"

class OtherSettingServices {
  async getPenalties(params) {
    try {
      const data = await amgApi.post(
        "management/charge-back/get-penalties",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async updatePenalty(params) {
    try {
      const data = await amgApi.post(
        "management/charge-back/update-penalty",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getPenaltyTracking(params) {
    try {
      const data = await amgApi.post(
        "management/charge-back/get-penalty-tracking",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}

export default new OtherSettingServices()
